<div class="header-container" [ngClass]="toggleDirection === toggleDirections.LEFT ? 'justify-content-between' : 'flex-md-row flex-row-reverse justify-content-md-between'">
  <div class="header-content">
    <div class="d-none d-md-block">
      <img [src]="constants.applicationLogoUrl" alt="Company Logo" class="logo" />
    </div>
    <div class="d-block d-md-none w-100">
      <p-button icon="pi pi-bars" styleClass="sidebar-toggle-btn" (click)="showSidebar = true"></p-button>
    </div>
    <div class="d-none d-md-flex align-items-md-end menu-items-container ms-2">
      <div *ngFor="let menuItem of menuItems">
        <div
          *ngIf="menuItem.isAccessible && !menuItem?.subMenuOptions?.length"
          [routerLink]="menuItem.routerLink"
          routerLinkActive="active-route"
          class="menu-item"
          (click)="showSidebar = false"
          appItemHover
        >
          <i [class]="menuItem.icon"></i>
          <span class="ms-1">{{ menuItem.title }}</span>
        </div>
        <div *ngIf="menuItem.isAccessible && menuItem?.subMenuOptions?.length" class="d-none d-md-block" appItemHover>
          <div
            class="menu-item"
            [ngClass]="{
              'active-route': currentActiveParentRoute === menuItem.id
            }"
            (click)="childMenuOptions.toggle($event)"
          >
            <i [class]="menuItem.icon"></i>
            <span class="ms-1">{{ menuItem.title }}</span>
            <i class="pi pi-chevron-down parent-route-chevron"></i>
          </div>
          <p-overlayPanel styleClass="header-sub-menu" #childMenuOptions>
            <ng-template pTemplate="content">
              <div *ngFor="let subMenuItem of menuItem.subMenuOptions">
                <div
                  *ngIf="subMenuItem.isAccessible"
                  [routerLink]="subMenuItem.routerLink"
                  routerLinkActive="active-sub-route"
                  class="sub-menu-item"
                  (click)="childMenuOptions.hide()"
                >
                  <i [class]="subMenuItem.icon"></i>
                  <span class="ms-1">{{ subMenuItem.title }}</span>
                </div>
              </div>
            </ng-template>
          </p-overlayPanel>
        </div>
      </div>
    </div>
  </div>
  <div [class.me-2]="toggleDirection === toggleDirections.RIGHT" class="d-flex">
    <div *ngIf="currentUser?.role === ROLES_ENUM.globalAdmin">
      <i class="pi pi-bell bell-icon" (click)="onClickAddNotification()" pTooltip="Notification" tooltipPosition="left"></i>
    </div>
    <app-header-elements></app-header-elements>
  </div>
</div>
<div *ngIf="showProgressBar" class="progress-bar-container">
  <p-progressBar mode="indeterminate" [style]="{ height: '2px' }"></p-progressBar>
</div>

<app-sidebar
  class="d-block d-md-none"
  [showSidebar]="showSidebar"
  [currentActiveParentRoute]="currentActiveParentRoute"
  [toggleDirection]="toggleDirection"
  (onClose)="showSidebar = false"
></app-sidebar>
<p-dialog header="Broadcast Notification" [modal]="true" [(visible)]="addBroadcastNotificationVisible" [style]="{ width: '25rem' }">
  <form [formGroup]="broadcastNotificationform">
    <div class="flex align-items-center gap-3 mb-3">
      <div class="col-sm-12">
        <div class="mt-3 flex flex-column">
          <span>
            <label>Subject</label>
            <input pInputText type="text" class="w-100 me-2 custom-input-edit form-control p-inputtext-lg" formControlName="subject" [maxlength]="20" />
          </span>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="mt-3 flex flex-column">
        <span>
          <div class="d-flex justify-content-between">
            <label htmlFor="message">Message</label>
            <div>{{ broadcastNotificationform.controls.message?.value?.length }} / 100</div>
          </div>
          <textarea rows="4" class="custom-textarea-edit form-control message-text-area h-100 w-100" pInputTextarea formControlName="message" [maxlength]="100"></textarea>
        </span>
      </div>
    </div>
    <div class="flex gap-2 mt-3 dialog-footer">
      <button
        pButton
        class="p-button-outlined p-button-danger"
        routerLinkActive="router-link-active"
        pTooltip="Cancel"
        tooltipPosition="top"
        (click)="addBroadcastNotificationVisible = false"
      >
        <i class="pi pi-times"></i><span class="d-none d-md-block ms-1">Cancel</span>
      </button>
      <button
        pButton
        class="p-button p-button-primary ms-2"
        routerLinkActive="router-link-active"
        tooltipPosition="top"
        (click)="onClickSaveNotification()"
        [disabled]="broadcastNotificationform.invalid"
      >
        <i class="pi pi-save"></i><span class="d-none d-md-block ms-1">Send</span>
      </button>
    </div>
  </form>
</p-dialog>
