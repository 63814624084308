import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/services';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { ROLES_ENUM } from 'src/app/shared/constants';
import { AppToasterService, CommonService } from 'src/app/shared/services';
import { MenuConfigService } from 'src/app/shared/services/menu-config.service';
import { MenuItems, ToggleDirections } from './header.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseComponent implements OnInit {
  @Input() toggleDirection: ToggleDirections = ToggleDirections.LEFT;
  currentActiveParentRoute!: string;
  menuItems!: Array<any>;
  toggleDirections = ToggleDirections;
  showSidebar = false;
  showProgressBar = false;
  ROLES_ENUM = ROLES_ENUM;
  addBroadcastNotificationVisible = false;
  broadcastNotificationform = new FormGroup({
    subject: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
  });
  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly commonService: CommonService,
    private readonly menuConfigService: MenuConfigService,
    private readonly toasterService: AppToasterService,
  ) {
    super();
    this.setActiveRoute();
  }

  ngOnInit(): void {
    this.menuConfigService.modifyMenuConfig(MenuItems);
    this.getCurrentUser();
  }

  subscribeToAPICallProgress(): void {
    //Call this method to show global progress bar
    this.commonService.isApiCallInProgress$.pipe(takeUntil(this.destroy$)).subscribe((isApiCallInProgress) => {
      this.showProgressBar = isApiCallInProgress;
      this.cdr.detectChanges();
    });
  }

  getCurrentUser(): void {
    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.currentUser = res;
          this.menuItems = this.menuConfigService.getMenuConfigItems();
          this.cdr.detectChanges();
        },
      });
  }

  setActiveRoute(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentActiveParentRoute = event.url.split('/')[1];
      } else if (event instanceof Scroll) {
        this.currentActiveParentRoute = event.routerEvent.url.split('/')[1];
      }
      this.cdr.detectChanges();
    });
  }
  onClickAddNotification() {
    this.addBroadcastNotificationVisible = true;
  }
  onClickSaveNotification() {
    if (this.broadcastNotificationform.invalid) {
      return;
    }
    this.addBroadcastNotificationVisible = false;
    this.authService
      .sendNotification(this.broadcastNotificationform.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.toasterService.success(res.message);
        },
        error: (err) => {
          this.toasterService.success(err);
        },
      });
  }
}
